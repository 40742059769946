/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"


const Footer = () => (
  <footer
    className="site-footer"
    sx={{
      bg: "siteColor",
    }}
  >
    <div className="container" style={{verticalAlign:'middle'}}>
      <p>
        忻旅科技股份有限公司 RevtelTech
      </p>
    </div>
  </footer>
)

export default Footer
